import React, { useState } from 'react';
import classes from './BuyNow.module.css';
import { FaShoppingCart, FaMoneyBillAlt, FaStore, FaArrowLeft, FaCheck, FaTimes } from 'react-icons/fa';

const BuyNow = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFormVisible, setFormVisible] = useState(false);

  const handleButtonClick = () => {
    setModalOpen(true);
    setFormVisible(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setFormVisible(false);
  };

  const handleCashOnDeliveryClick = () => {
    setFormVisible(true);
  };

  const handleBackClick = () => {
    setFormVisible(false);
  };

  return (
    <>
      <div className={classes.floatingButtonContainer}>
        <button className={classes.floatingButton} onClick={handleButtonClick}>
          <FaShoppingCart className={classes.blinkingIcon} /> Buy Now
        </button>
      </div>

      {isModalOpen && (
        <div className={classes.modalOverlay} onClick={handleCloseModal}>
          <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
            {!isFormVisible ? (
              <>
                <h2>Select Payment Method</h2>
                <div className={classes.paymentOptions}>
                  <div className={classes.paymentOption} onClick={handleCashOnDeliveryClick}>
                    <FaMoneyBillAlt className={classes.icon} />
                    <p>Cash on Delivery</p>
                  </div>
                  <div className={classes.paymentOption}>
                    <FaStore className={classes.icon} />
                    <p>Payment Center</p>
                  </div>
                </div>
                <button className={classes.closeButton} onClick={handleCloseModal}>
                  <FaTimes /> Close
                </button>
              </>
            ) : (
              <>
                <h2>Cash on Delivery</h2>
                <form className={classes.form}>
                  <div className={classes.formGroup}>
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" required />
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" required />
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="contact">Contact</label>
                    <input type="text" id="contact" name="contact" required />
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="address">Address</label>
                    <textarea id="address" name="address" rows="4" required></textarea>
                  </div>
                  <div className={classes.buttonGroup}>
                    <button type="button" className={classes.backButton} onClick={handleBackClick}>
                      <FaArrowLeft /> Back
                    </button>
                    <button type="submit" className={classes.submitButton}>
                      <FaCheck /> Submit
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BuyNow;
