import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './screens/Homepage';
import PaymentSuccess from './screens/PaymentSuccess';
import AccountActivation from './screens/AccountActivation';
import NotFound from './screens/NotFound';

const AppRoutes = ({ isSidebarOpen, toggleSidebar }) => {  // Added props here
  return (
    <Routes>
      {/* Public routes (not protected) */}
      <Route path="/" element={<Homepage />} />
      <Route path="/success" element={<PaymentSuccess />} />
      <Route path="/activate" element={<AccountActivation />} />

      {/* Fallback route for unknown paths */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
