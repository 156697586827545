import React, {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicFooter from '../components/layout/Footer/PublicFooter';
import BuyNow from './BuyNow';
import classes from './Homepage.module.css';
import Parent from '../assets/image/parent.jpg';
import Person1 from '../assets/image/person1.jpg';

const Homepage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <img src="https://impex.428am.xyz/images/logo/impex-logo.svg" alt="J&K Impex Group Corporation Logo" className={classes.logoImage} />
        <div className={classes.logoText}>J&K Impex Group Corporation</div>
      </header>
      
      <section className={classes.headlineSection}>
        <div className={classes.headlineContent}>
          <h1>Discover Sunkist Fruit & Jell</h1>
          <p>The perfect blend of refreshing fruit flavors and delightful jelly texture.</p>
        </div>
      </section>

      <section className={classes.subHeadlineSection}>
        <marquee behavior="scroll" direction="left" scrollamount="10">
          <h2>Experience the Ultimate Snack with Sunkist Fruit & Jell! Refreshing, Delicious, and Fun - Grab Yours Today!</h2>
        </marquee>
      </section> 

      <section className={classes.imageSection}>
        <div className={classes.imageContainer}>
          <img src="https://impex.428am.xyz/images/products/fruit-mate-blueberry-hibiscus-box-2.png" alt="Sunkist Fruit & Jell" />
          <div className={classes.imageDescription}>
            <h3>Refreshing Blueberry Hibiscus</h3>
            <p>Experience the burst of blueberry with the delightful hint of hibiscus.</p>
          </div>
        </div>
      </section>

      <section className={classes.valuePropositionSection}>
        <h2>Why Choose Sunkist Fruit & Jell?</h2>
        <ul className={classes.valuePropositionList}>
          <li>
            <img src="https://impex.428am.xyz/images/products/mandarin.png" alt="Real Fruit Juice" className={classes.icon}/>
            Made with real fruit juice
          </li>
          <li>
            <img src="https://impex.428am.xyz/images/products/lemon.png" alt="No Artificial Flavors" className={classes.icon}/>
            No artificial flavors or colors
          </li>
          <li>
            <img src="https://impex.428am.xyz/images/products/orange.png" alt="Fun and Healthy Snack" className={classes.icon}/>
            A fun and healthy snack for all ages
          </li>
        </ul>
      </section>

      <section className={classes.productFeatureAndBenefitsSection}>
        <h2>Product Features & Benefits</h2>
        <div className={classes.featureGrid}>
          <div className={classes.featureItem}>
            <img src="https://impex.428am.xyz/images/products/orange.png" alt="Real Fruit Flavor" className={classes.featureIcon} />
            <div className={classes.featureText}>
              <h3>Real Fruit Flavor</h3>
              <p>Experience the authentic taste of real fruit in every bite.</p>
            </div>
          </div>
          <div className={classes.featureItem}>
            <img src="https://impex.428am.xyz/images/products/lemon-2-boxes.png" alt="Convenient Packaging" className={classes.featureIcon} />
            <div className={classes.featureText}>
              <h3>Convenient Packaging</h3>
              <p>Perfect for on-the-go snacking and easy storage.</p>
            </div>
          </div>
        </div>
      </section>

      <section className={classes.socialProofSection}>
        <h2>What Our Customers Say</h2>
        <div className={classes.testimonials}>
          <div className={classes.testimonialItem}>
            <img src={Parent} alt="Jena Mkcnee" className={classes.customerImage} />
            <blockquote>"A delicious and healthy snack that my kids love!"</blockquote>
            <p>- Jena Mkcnee</p>
          </div>
          <div className={classes.testimonialItem}>
            <img src={Person1} alt="Tim Browly" className={classes.customerImage} />
            <blockquote>"Sunkist Fruit & Jell is a game-changer for my snack time!"</blockquote>
            <p>- Tim Browly</p>
          </div>
        </div>
      </section>

      <section className={classes.detailedProductInformationSection}>
        <h2>Detailed Product Information</h2>
        <div className={classes.productInfo}>
          <img src="https://impex.428am.xyz/images/products/fruit-mate-grape-fruit-black-tea-box-2.png" alt="Sunkist Fruit & Jell Pack" className={classes.productImage} />
          <div className={classes.productDetails}>
            <p><strong>Ingredients:</strong> High-quality real fruit juice, natural flavors</p>
            <p><strong>Benefits:</strong> Tasty and nutritious snack, delightful snacking experience</p>
            <p><strong>Description:</strong> Sunkist Fruit & Jell combines high-quality ingredients to create a tasty and nutritious snack. Each pack is filled with real fruit juice and natural flavors, ensuring a delightful snacking experience.</p>
          </div>
        </div>
      </section>

      <section className={classes.faqSection}>
        <h2>Frequently Asked Questions</h2>
        <div className={classes.accordionItem}>
          <button className={classes.accordionButton} onClick={() => toggleAccordion(0)}>
            What are the ingredients in Sunkist Fruit & Jell?
          </button>
          {activeIndex === 0 && (
            <div className={classes.accordionContent}>
              <p>Our product is made with real fruit juice, natural flavors, and a blend of healthy ingredients to ensure a delicious and nutritious snack.</p>
            </div>
          )}
        </div>
        <div className={classes.accordionItem}>
          <button className={classes.accordionButton} onClick={() => toggleAccordion(1)}>
            Is Sunkist Fruit & Jell suitable for children?
          </button>
          {activeIndex === 1 && (
            <div className={classes.accordionContent}>
              <p>Yes, Sunkist Fruit & Jell is a healthy snack option for children and adults alike.</p>
            </div>
          )}
        </div>
      </section>


      <PublicFooter />
      <BuyNow />
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Homepage;
